/* eslint-disable react/style-prop-object */
import React from 'react';
import styled from 'styled-components';
import Footer from '../footer';
import Navbar from '../header';

const Dove = styled.section`
height:780px;
display:flex;
justify-content:center;
align-items:center;
gap:2rem;
.text-data
{
    
    height:400px;
    width:40vw;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
.nome
{
 font-size:1.8rem;
 line-height:92.6%;
 padding:0;
 letter-spacing:1px;

}
.sub {
  margin:0;
  font-weight:300;
    

}

.orari
{
   
    text-transform:Capitalize;
    font-size:2.2rem;
    text-align:center;
    
}
.map{
  
  box-shadow: 0.1rem 0.1rem 4px rgba(27,27,27, 0.4);
  width:50vw;
  height:80vh;
}
@media screen and (max-width:800px){
  margin: 8vh 0;
  height: max-content;
  gap:2rem;
.text-data
{
   
    width:80vw;
   
    height:100%;
   
}
flex-direction:column;
font-size:1.2rem;
.nome
{
 font-size:1.8rem;
 text-align:center;
 margin: 2vh 0;

 
}
.sub{
 font-size: 0.6rem;
 text-align:center;
 margin: 0vh 4vh;
 margin-bottom:2vh;
}
.orari
{
    font-size:1rem;
    text-align:center;
    margin: 1vh 4vh;
}
.map{
    height:50vh;
    width:80vw;
    
}
}
`;
const Adj = styled.section`
position:relative;
bottom:0;
width:100%;
margin:0;
margin-top:0%`;
function Where() {
  React.useEffect(() => {
    document.title = 'Digital Photo RC - Dove Siamo';
  }, []);
  return (
    <>
      <Navbar />

      <Dove>
        <iframe
          title="loco"
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.786624508083!2d15.635810915326928!3d38.09863017970184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13145a81b22412f7%3A0x869c14256a141cbc!2sDIGITAL%20PHOTO%20DI%20FRANCO%20FONTANA!5e0!3m2!1sit!2sit!4v1645452317694!5m2!1sit!2sit"
          style={{ borderRadius: 24, border: 0 }}
          allowFullScreen=""
          loading="lazy"
        />
        <div className="text-data">
          <h1 className="txt nome">Digital Photo di Fontana Francesco</h1>
          <h3 className="txt sub">Via Nicola Furnari, 47, 89129 Reggio Calabria RC.</h3>

          <h5 className="txt orari">
            lunedì-venerdì:
            9-13; 16-20

            sabato-domenica:
            Chiuso
          </h5>
        </div>
      </Dove>

      <Adj>
        <Footer />
      </Adj>
    </>
  );
}
export default Where;
