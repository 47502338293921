import React from 'react';
import styled from 'styled-components';
import Navbar from '../header';
import Submit from '../form';
import Footer from '../footer';

const ContattiForm = styled.div`
position:relative;
height:600px;
justify-content:center;

.text
{position:relative;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding:0;
}
@media screen and (max-width:800px){
    
    padding: 0;
    margin-top:0%;
    margin:0;
    margin-bottom:20px;
    min-height:520px;
    height:max-content;
    padding-bottom:60px;
    width:85vw;
    
    
}
}
`;

function Contact() {
  React.useEffect(() => {
    document.title = 'Digital Photo RC - Manda una richiesta';
  }, []);
  return (
    <>
      <Navbar />

      <ContattiForm>
        <Submit />
      </ContattiForm>

      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

export default Contact;
